import {
    ExpandLess, ExpandMore
} from '@mui/icons-material';
import {
    Grid, List, ListItem,
    ListItemButton, ListItemText, Collapse
} from "@mui/material";
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-scroll'

export function CollapsibleSectionIndex({ section, index }) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem dense>
                <ListItemButton onClick={handleClick}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item xs={10}>
                            <Link activeClass="active"
                                to={section.id.toString()}
                                spy={true}
                                smooth={true}
                                offset={-16}
                                duration={500}
                                delay={100}
                                isDynamic={true}
                                //onSetActive={this.handleSetActive}
                                //onSetInactive={this.handleSetInactive}
                                ignoreCancelEvents={false}
                                spyThrottle={500}
                            >
                                <ListItemText primary={`${index + 1}. ${section.label}`} />
                            </Link>
                        </Grid>
                        <Grid item xs={1}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </Grid>
                    </Grid>


                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        section.subSections.map((ss, i) => (
                            <ListItem key={ss.id} dense sx={{ pl: 4 }}>
                                <ListItemButton>
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid item xs={12}>
                                            <Link activeClass="active"
                                                to={ss.id.toString()}
                                                spy={true}
                                                smooth={true}
                                                offset={-16}
                                                duration={500}
                                                delay={100}
                                                isDynamic={true}
                                                //onSetActive={this.handleSetActive}
                                                //onSetInactive={this.handleSetInactive}
                                                ignoreCancelEvents={false}
                                                spyThrottle={500}
                                            >
                                                <ListItemText primary={`${index + 1}.${i + 1}. ${ss.label}`} />
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                        ))
                    }

                </List>
            </Collapse>
        </>
    );
};