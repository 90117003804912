import {
    Edit as EditIcon, Lock, LockOpen
} from '@mui/icons-material';
import {
    Box, Chip, Typography, Card, CardContent, CardHeader, IconButton, Avatar, Tooltip
} from "@mui/material";
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSectionToEdit } from './CollateralSlice';
import { red } from '@mui/material/colors';
import { EditSection } from './EditSection';

export function ShowSubSection({ formName, section, sectionIndex, subSection, subSectionIndex }) {
    const dispatch = useDispatch();

    const [openEditor, setOpenEditor] = useState(false);

    function createMarkup(content) {
        return { __html: content }
    }

    const editSectionHandler = async () => {
        await dispatch(setSectionToEdit({
            id: section.id,
            formName: formName,
            sectionName: section.name,
            subSectionName: subSection.name,
            subSectionIndex: subSectionIndex
        }));

        setOpenEditor(true);
    }

    return (
        <>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            {`${sectionIndex + 1}.${subSectionIndex + 1}`}
                        </Avatar>
                    }
                    action={
                        <Box sx={{ display: "flex" }}>
                            <Tooltip title="Unlock">
                                <IconButton aria-label="unlock" onClick={() => setOpenEditor(true)}>
                                    <LockOpen color='disabled' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit" onClick={editSectionHandler}>
                                    <EditIcon color='secondary' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                    title={
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                            <Typography color="primary" variant="subtitle2" style={{ fontSize: '0.9rem' }}>{section.label}</Typography>
                            {/* <Chip icon={<Lock />} label="Locked for editing by Sujit Bhoir" variant="outlined" color="info" size="small" />
                            <Chip icon={<Lock />} label="Read-only" variant="outlined" color="default" size="small" /> */}
                            <LockOpen color="disabled" />
                        </Box>
                    }
                    subheader={subSection.label}
                    sx={{ borderBottom: "solid 1px #ccc" }}
                />
                <CardContent>
                    <div dangerouslySetInnerHTML={createMarkup(subSection.content)} />
                </CardContent>
            </Card>

            {openEditor && <EditSection s={section} selectedSubSectionIndex={subSectionIndex} closeHandler={() => setOpenEditor(false)} />}
        </>
    );
};