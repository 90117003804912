import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Chip, Collapse, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { selectDocToSign } from './SignDocumentSlice';
import WebViewer from '@pdftron/webviewer';
import './SignDocument.css';
import { PageTitle } from '../../../components/layout/PageTitle';
import { faFilePdf, faSign, faSignature } from '@fortawesome/free-solid-svg-icons';
import { ArrowBackIos, ArrowForwardIos, Download, ExpandLess, ExpandMore, KeyboardReturnOutlined, TurnedIn } from '@mui/icons-material';
import { Signers } from '../Signers/Signers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMsal } from "@azure/msal-react";
import moment from "moment";

export const SignDocument = ({ blob }) => {
    const msalInstance = useMsal().instance;
    const activeAccount = msalInstance.getActiveAccount();

    const [instance, setInstance] = useState(null);
    const [annotManager, setAnnotatManager] = useState(null);
    const [annotPosition, setAnnotPosition] = useState(0);

    
    const [email, setEmail] = useState(null);

    const doc = useSelector(selectDocToSign);

    const [signers, setSigners] = useState([
        {
            id: 'olivia.allen@getwellsoonphysicians.com',
            name: 'Olivia Lou Allen',
            email: 'olivia.allen@getwellsoonphysicians.com',
            title: 'Counselor - Mental Health',
            order: 1,
            role: 'Provider',
            status: 'pending'
        }
    ]);

    useEffect(() => {
        if (activeAccount && !signers.some(s => s.id === activeAccount.username)) {
            const newSigner = {
                
                id: activeAccount.username,
                name: activeAccount.name,
                email: activeAccount.username,
                title: 'Admin',
                order: 2,
                role: 'Payor',
                status: 'pending'
            };
            
            setSigners([...signers, newSigner]);
        }
    }, []);

    useEffect(() => {
        if (activeAccount) {
            setEmail(activeAccount.username);
            console.log("Active account: ", activeAccount);
        } else {
            setEmail(null);
        }
    }, [activeAccount]);

    //const user = useSelector(selectUser);
    const { docRef, docId } = doc;
    //const { email } = user;
    
    const [expandInfo, setExpandInfo] = useState(true);
    const [expandAttachments, setExpandAttachments] = useState(false);
    const [expandSigners, setExpandSigners] = useState(false);

    const viewer = useRef(null);

    useEffect(() => {
        WebViewer(
            {
                fullAPI: true,
                path: 'webviewer',
                disabledElements: [
                    'ribbons',
                    'toggleNotesButton',
                    'searchButton',
                    'menuButton',
                    'rubberStampToolGroupButton',
                    'stampToolGroupButton',
                    'fileAttachmentToolGroupButton',
                    'calloutToolGroupButton',
                    'undo',
                    'redo',
                    'eraserToolButton'
                ],
            },
            viewer.current,
        ).then(async instance => {
            const { docViewer, annotManager, Annotations } = instance;
            setAnnotatManager(annotManager);

            setInstance(instance);

            // select only the insert group
            instance.setToolbarGroup('toolbarGroup-Insert');

            instance.setFitMode(instance.UI.FitMode.FitWidth);


            // load document
            //const storageRef = storage.ref();
            //const URL = await storageRef.child(docRef).getDownloadURL();
            //const URL = "https://localhost:3000/response.pdf";
            //docViewer.loadDocument(URL);
            //docViewer.loadDocument(blob);
            instance.loadDocument(await instance.Core.createDocument(blob, { extenstion: 'pdf' }));

            const normalStyles = (widget) => {
                if (widget instanceof Annotations.TextWidgetAnnotation) {
                    return {
                        'background-color': '#a5c7ff',
                        color: 'white',
                    };
                } else if (widget instanceof Annotations.SignatureWidgetAnnotation) {
                    return {
                        border: '1px solid #a5c7ff',
                    };
                }
            };

            annotManager.on('annotationChanged', (annotations, action, { imported }) => {
                if (imported && action === 'add') {
                    annotations.forEach(function (annot) {
                        if (annot instanceof Annotations.WidgetAnnotation) {
                            Annotations.WidgetAnnotation.getCustomStyles = normalStyles;

                            if (!annot.fieldName.startsWith(email)) {
                                annot.Hidden = true;
                                annot.Listable = false;
                            }
                        }
                    });
                }
            });
        });
    }, [docRef, email]);

    useEffect(() => {
        if (instance) {
            (async () => {
                // instance.setToolbarGroup('toolbarGroup-Insert');
                // instance.setFitMode(instance.UI.FitMode.FitWidth);
                // instance.loadDocument(await instance.Core.createDocument(blob, { extenstion: 'pdf' }));
            })();

        }
    }, [blob, instance])

    const nextField = () => {
        let annots = annotManager.getAnnotationsList();
        annots = annots.filter(a => a.fieldName?.startsWith(email));

        if (annots[annotPosition]) {
            annotManager.jumpToAnnotation(annots[annotPosition]);
            if (annots[annotPosition + 1]) {
                setAnnotPosition(annotPosition + 1);
            }
        }
    }

    const prevField = () => {
        let annots = annotManager.getAnnotationsList();
        annots = annots.filter(a => a.fieldName?.startsWith(email));

        if (annots[annotPosition]) {
            annotManager.jumpToAnnotation(annots[annotPosition]);
            if (annots[annotPosition - 1]) {
                setAnnotPosition(annotPosition - 1);
            }
        }
    }

    const completeSigning = async () => {

        instance.UI.downloadPdf({
            includeAnnotations: true,
            flatten: true,
        });

        // const { documentViewer, annotationManager } = instance.Core;
        // const doc = documentViewer.getDocument();
        // const xfdfString = await annotationManager.exportAnnotations();
        // const options = { xfdfString, flatten: true };
        // const data = await doc.getFileData(options);
        // const arr = new Uint8Array(data);
        // const blob = new Blob([arr], { type: 'application/pdf' });

        // var fileName = "Signed Contract.pdf";
        // //Check the Browser type and download the File.
        // var isIE = false || !!document.documentMode;
        // if (isIE) {
        //     window.navigator.msSaveBlob(blob, fileName);
        // } else {
        //     var url = window.URL || window.webkitURL;
        //     var link = url.createObjectURL(blob);
        //     var a = document.createElement("a");
        //     a.setAttribute("download", fileName);
        //     a.setAttribute("href", link);
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        // }
    }

    if (!email)
    return (<div>...</div>)

    return (
        <div className={'prepareDocument'}>
            <Box>
                <PageTitle
                    title={"Sign Document"}
                    faIcon={faSignature}
                    helperText={`Please review all sections of the document and electronically sign it.`}
                    chips={[
                        <Chip label={`Version: 1.0`} />
                    ]}
                    rightAction={
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button color="secondary" variant="outlined" startIcon={<ArrowBackIos />} onClick={prevField}
                                >Previous field</Button>
                            </Grid>
                            <Grid item>
                                <Button color="secondary" variant="outlined" endIcon={<ArrowForwardIos />} onClick={nextField}
                                >Next field</Button>
                            </Grid>

                            <Grid item sx={{ ml: 4 }}>
                                <Button color="primary" variant="outlined" startIcon={<Download />} onClick={completeSigning}
                                >Download</Button>
                            </Grid>
                            <Grid item>
                                <Button color="primary" variant="contained" startIcon={<TurnedIn />} onClick={completeSigning}
                                >Complete Signing</Button>
                            </Grid>
                        </Grid>
                    }
                    sx={{ pb: 3 }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={3}>

                        <Box sx={{
                            bgcolor: 'background.paper', border: "solid 1px #ccc",
                            borderRadius: "0.25rem", mb: 3
                            //overflowY: "scroll"
                        }}>
                            <Box py={1} px={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Box>
                                    <Typography variant="h6">PHO Agreement</Typography>
                                    <Typography variant="caption">Physician Hospital Agreement</Typography>
                                </Box>
                                <IconButton onClick={() => setExpandInfo(!expandInfo)}>
                                    {expandInfo ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            </Box>
                            <Collapse in={expandInfo} timeout="auto" unmountOnExit>
                                <Divider />
                                <Box>

                                    <Typography variant="subtitle1" color="primary" sx={{ p: 2 }}>Agreement between Olivia Lou Allen and Simplify Healthcare.</Typography>
                                    <List dense >
                                        {
                                            [
                                                "Physician Hospital Agreement"
                                            ].map(a => (
                                                <ListItem key={a} disableGutters>
                                                    <ListItemButton>
                                                        <ListItemIcon><FontAwesomeIcon icon={faFilePdf} /></ListItemIcon>
                                                        <ListItemText
                                                            primary={a} primaryTypographyProps={{ color: "#0288d1" }}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Version</TableCell>
                                                    <TableCell>1.0</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Draft</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Requested By</TableCell>
                                                    <TableCell>John Doe</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Requested On</TableCell>
                                                    <TableCell>{moment().format("MMM Do, YYYY")}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Sign By</TableCell>
                                                    <TableCell>{moment().add(30, 'days').format("MMM Do, YYYY")}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Typography variant="body2" sx={{ p: 2 }}>Please ensure all necessary attachments and included in the envelope
                                        prior to sending the document for signing.</Typography>
                                </Box>

                                {/* <Divider />
                            <Box py={2} px={2}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button onClick={applyFields} variant="contained" size="large" color="primary"
                                        startIcon={<Send />}>
                                        Send for signing
                                    </Button>
                                </Box>
                            </Box> */}
                            </Collapse>
                        </Box>

                        <Box sx={{
                            bgcolor: 'background.paper', border: "solid 1px #ccc",
                            borderRadius: "0.25rem", mb: 3
                            //overflowY: "scroll"
                        }}>
                            <Box py={1} px={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Box>
                                    <Typography variant="h6">Attachments</Typography>
                                    <Typography variant="caption">Additional attachments included with the document.</Typography>
                                </Box>
                                <IconButton onClick={() => setExpandAttachments(!expandAttachments)}>
                                    {expandAttachments ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            </Box>
                            <Collapse in={expandAttachments} timeout="auto" unmountOnExit>
                                <Divider />
                                <Box>
                                    <List dense >
                                        {
                                            [
                                                "Policies and Procedures Manual",
                                                "Reimbursement Methodology",
                                                "Provider Information Form",
                                                "Networks and Products",
                                                "Delegation Agreement"
                                            ].map(a => (
                                                <ListItem key={a} disableGutters>
                                                    <ListItemButton>
                                                        <ListItemIcon><FontAwesomeIcon icon={faFilePdf} /></ListItemIcon>
                                                        <ListItemText
                                                            primary={a} primaryTypographyProps={{ color: "#0288d1" }}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                </Box>
                            </Collapse>
                        </Box>

                        <Box sx={{
                            bgcolor: 'background.paper', border: "solid 1px #ccc",
                            borderRadius: "0.25rem"
                            //overflowY: "scroll"
                        }}>
                            <Box py={1} px={2}>
                                <Typography variant="h6">Signers</Typography>
                                <Typography variant="caption">eSign requests are issued in the order listed here.</Typography>
                            </Box>
                            <Divider />
                            <Box py={1}>
                                <Signers signers={signers} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <div className="webviewer" ref={viewer}></div>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};