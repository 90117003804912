import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { faBars, faBell, faFileContract, faGear, faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Badge, Box, Button, IconButton, Menu, MenuItem,
    Stack, Typography
} from '@mui/material';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import shLogo from './../../assets/images/eProviderSync.png'; // BCBSNE6Blue.png
import { SignInButton } from "./../security/SignInButton";

const pages = [
    { label: "e-Signature", path: "/esignature", icon: faSignature, color: "inherit" },
    { label: "Collaterals", path: "/collaterals", icon: faFileContract, color: "inherit" },
    { label: "Settings", path: "/settings", icon: faGear, color: "inherit" },
];

function renderLogo() {
    return (
        <Stack
            component={RouterLink}
            to="/"
            sx={{ mr: { xs: 0, md: 1 }, display: 'flex', flexGrow: 1, alignItems: "flex-start", textDecoration: "none" }}
        >
            <Box
                component="img"
                sx={{
                    height: { xs: '1rem', md: '1rem' }, mr: { xs: 0, md: 1 }, objectFit: "scale-down",
                    marginLeft: "1rem"
                }}
                alt="Self Service Portal"
                src={shLogo}
            />
            <Typography variant="subtitle2" noWrap sx={{
                padding: "0.25rem 0.5rem",
                fontWeight: "bold",
                background: "#f3c36a",
                borderRadius: "0.25rem",
                fontSize: "11px",
                fontColor: "#483d8b",
                marginLeft: "0.5rem"
            }}>SELF SERVICE PORTAL</Typography>
        </Stack>
    );
}

const NavMenu = () => {
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    if (isAuthenticated) {
        return (
            <React.Fragment>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <FontAwesomeIcon icon={faBars} color="#355c7d" />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {pages.map((page) => (
                            <MenuItem key={page.path} onClick={handleCloseNavMenu}
                                component={RouterLink}
                                to={page.path}>
                                <Typography textAlign="center">{page.label}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

                {/*{ renderLogo() }*/}

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: { md: 'flex-end' } }}>
                    {pages.map((page) => (
                        <Button
                            key={page.path}
                            onClick={handleCloseNavMenu}
                            LinkComponent={RouterLink}
                            to={page.path}
                            startIcon={<FontAwesomeIcon icon={page.icon} />}
                            sx={{ mr: '2rem' }}
                            color={page.color}
                        >
                            {page.label}
                        </Button>
                    ))}

                    <IconButton
                        onClick={handleCloseNavMenu}
                        LinkComponent={RouterLink}
                        to={"/"}
                        sx={{ mr: '2rem' }}
                        color={"secondary"}>
                        <Badge badgeContent={2} color="secondary">
                            <FontAwesomeIcon icon={faBell} />
                        </Badge>
                    </IconButton>
                </Box>
            </React.Fragment>
        );
    } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
        // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. 
        // Processing the server response takes a render cycle or two
        return (
            <React.Fragment>
                <SignInButton />
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                {renderLogo()}
            </React.Fragment>
        );
    }
};
export default NavMenu;
