import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import '@testing-library/react';
import { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { useState } from 'react';
import { DiffDOM, nodeToObj, stringToObj } from "diff-dom";

export function ContentEditor({ content, recommendation, fetchRecommendations }) {
    

    const editorRef = useRef(null);
    const diffDOM = new DiffDOM();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState(null);

    useEffect(() => {
        if (activeAccount) {
            setUserName(activeAccount.name);
            setUserId(activeAccount.localAccountId);
        } else {
            setUserName(null);
            setUserId(null);
        }
    }, [activeAccount]);

    useEffect(() => {
        if (!recommendation) return;

        const diff = JSON.parse(recommendation.contentDiff);
        //const elComment = document.createComment(recommendation.comments);
        const elComment = `<!-- ${recommendation.comments} -->`

        var elWrapper = document.createElement("div");
        elWrapper.innerHTML = (content + elComment).trim();

        var elUpdatedNew = elWrapper.firstChild;
        const result = diffDOM.apply(elUpdatedNew, diff);
        const updatedContent = elUpdatedNew.parentNode.innerHTML;
        editorRef.current.setContent(updatedContent);

    }, [recommendation])

    const log = () => {
        if (editorRef.current) {
            var elOrginal = stringToObj(content);
            var elUpdated = stringToObj(editorRef.current.getContent());

            //const diff = diffDOM.diff(elOrginal, elUpdated);
            const diff = JSON.parse('[{"action":"modifyTextElement","route":[3,7,0,0],"oldValue":"Failure to abide by any of this Contract&rsquo;s provisions may result in termination of this Contract.","newValue":"Failure to abide by any of this Contract&rsquo;s provisions may result in "},{"action":"addElement","route":[3,7,0,1],"element":{"nodeName":"SPAN","attributes":{"class":"mce-annotation tox-comment","data-mce-annotation-uid":"mce-conversation_67980413531665636089334","data-mce-annotation":"tinycomments"},"childNodes":[{"nodeName":"#text","data":"appropriate penalties"}]}},{"action":"addTextElement","route":[3,7,0,2],"value":"."}]');
            const elComment = document.createComment('tinycomments|2.1|data:application/json;base64,eyJtY2UtY29udmVyc2F0aW9uXzY3OTgwNDEzNTMxNjY1NjM2MDg5MzM0Ijp7InVpZCI6Im1jZS1jb252ZXJzYXRpb25fNjc5ODA0MTM1MzE2NjU2MzYwODkzMzQiLCJjb21tZW50cyI6W3sidWlkIjoibWNlLWNvbnZlcnNhdGlvbl82Nzk4MDQxMzUzMTY2NTYzNjA4OTMzNCIsImF1dGhvciI6IkFub24iLCJhdXRob3JOYW1lIjoiQW5vbiIsImNvbnRlbnQiOiJDaGFuZ2VkIHRlcm1pbmF0aW9uIGNsYXVzZSB0byBwZW5hbHR5LiIsImNyZWF0ZWRBdCI6IjIwMjItMTAtMTNUMDQ6NDE6MjkuMzM0WiIsIm1vZGlmaWVkQXQiOiIyMDIyLTEwLTEzVDA0OjQxOjI5LjMzNFoifV19fQ==');

            // var elUpdatedContentWrapper = document.createElement("div");
            // elUpdatedContentWrapper.innerHTML = editorRef.current.getContent();
            // var elComment = elUpdatedContentWrapper.childNodes[1];

            // console.log(document.createComment(elComment.nodeValue));
            // console.log(JSON.stringify(diff));
            // console.log(elComment.nodeValue);

            var elWrapper = document.createElement("div");
            elWrapper.innerHTML = (content + elComment).trim();
            //elWrapper.innerHTML = (content).trim();
            var elUpdatedNew = elWrapper.firstChild;
            const result = diffDOM.apply(elUpdatedNew, diff);
            const updatedContent = elUpdatedNew.parentNode.innerHTML;
            editorRef.current.setContent(updatedContent);
        }
    };

    const checkRecommendations = async e => {
        const elemId = e.target.id;
        if (elemId.toLowerCase().includes("-clause")) {
            console.log(e.target.innerText);
            fetchRecommendations(elemId, e.target.innerText);
            // const apiResult = await fetch("https://eps-clause-recommendation.azurewebsites.net/api/getrecommendations?code=Jp9WICKuN4iBbBgxKdgEDnGGakdglFf3lKrjjiphALa1AzFuEXXCqQ%3D%3D&clauseKey=ContractCV:Simplifyservicesandresponsibilities:SimplifySimplifyservicesandresponsibilities:quality-agreement-clause");
            // const response = await apiResult.json();
            // const data = response.data;

            // alert(data[0].text);
        }
    }

    const onEditorInit = (event, editor) => {
        editorRef.current = editor;

        console.log("Trying to set user");
        if (editorRef.current) {
            editorRef.current.plugins.flite.users.addUser({
                id: userId,
                name: userName
            });

            editorRef.current.plugins.flite.users.setCurrentUser(userId);
            console.log("User set successfully");
        }
    }

    var currentAuthor = userName;
    var userAllowedToResolve = userName;

    /*
    * Initial content for the editor, to be loaded into the editor using the
    * optional `rtc_initial_content_provider` option. This could pulled from
    * a database when using the editor in production.
    */
    const initialEditorContent = '<p><img style="display: block; margin-left: auto; margin-right: auto;" title="Tiny Logo" src="https://www.tiny.cloud/docs/images/logos/android-chrome-256x256.png" alt="TinyMCE Logo" width="128" height="128"></p><h2 style="text-align: center;">Welcome to the TinyMCE Real-Time Collaboration demo!</h2><p>This editor is collaborating with the other editor on the page. Try editing the content by adding images, lists, or any other currently supported content, it should appear in the other editor too!</p><p>All network requests made by this demo, fake or real, are logged in the browser console using <a href="https://netflix.github.io/pollyjs" target="_blank" rel="noopener">Polly.js</a> (the browser console is typically accessed using the F12 key).</p><h2>Got questions or need help?</h2><ul><li>Our <a class="XmceNoXnEditableX" href="https://www.tiny.cloud/docs/tinymce/6/">documentation</a> is a great resource for learning how to configure TinyMCE.</li><li>Have a specific question? Try the <a href="https://stackoverflow.com/questions/tagged/tinymce" target="_blank" rel="noopener"><code>tinymce</code> tag at Stack Overflow</a>.</li></ul><h2>Found a bug?</h2><p>If you think you have found a bug please create an issue on the <a href="https://github.com/tinymce/tinymce/issues">GitHub repo</a> to report it to the developers.</p><h2>Finally,</h2><p>Thanks for supporting TinyMCE! We hope it helps you and your users create great content.<br>All the best from the TinyMCE team.</p>';
    const documentId = "unique-document-id";

    /* Set up a connected users object for maintaining a list of connected users */
    const connectedUsers = {};

    return (
        <>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                //apiKey='tmt8pckuf74vvkck3d8bsn1i9tfz2898tggnggfny6j4a3o0'
                onInit={onEditorInit}
                initialValue={content || 'Loading...'}
                onClick={checkRecommendations}
                init={{
                    height: 550,
                    //menubar: true,
                    menubar: 'file edit view insert format tools table tc help',
                    menu: {
                        tc: {
                            title: 'Comments',
                            items: 'addcomment showcomments deleteallconversations'
                        }
                    },
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                        'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'advcode', 'help', 'wordcount', 'flite', 'tinycomments',
                        // 'rtc'
                    ],
                    external_plugins: {
                        'flite': '/tinymce/plugins/flite/plugin.min.js',
                        'tinycomments': '/tinymce/plugins/tinycomments/plugin.min.js'
                    },
                    toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | print | addcomment | flite | code | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    statusbar: false,
                    tinycomments_mode: 'embedded',
                    tinycomments_author: currentAuthor,
                    tinycomments_can_resolve: function (req, done, fail) {
                        var allowed = req.comments.length > 0 &&
                            req.comments[0].author === currentAuthor;
                        done({
                            canResolve: allowed || currentAuthor === userAllowedToResolve
                        });
                    },
                    flite: {
                        tooltipTemplate: "%a by %u on %dd-%mm-rabak",
                        isTracking: true,
                        userStyles: {
                            18: 2,
                            15: 4,
                            21: 3
                        },
                        debug: {
                            log: true,
                            error: true,
                            warn: true,
                            debug: true
                        },
                        // these are the default tooltip values. If you want to use this default configuration, just set flite.tooltips = true;
                        tooltips: true,
                        //	flite.tooltips = { show: true };
                        //	flite.tooltipTemplate = "%a by %u, first edit %t, last edit %T";
                        ignoreSelectors: ["[data-footnote-id]", "section.footnotes"],
                        user: {
                            id: userId,
                            name: userName
                        }
                    },
                    /* The following setup callback opens the comments sidebar when the editor loads */
                    setup: function (editor) {
                        editor.on('SkinLoaded', function () {
                            editor.execCommand("ToggleSidebar", false, "showcomments", { skip_focus: true });
                        })
                    },
                    // rtc_encryption_provider: () => Promise.resolve({ key: 'a secret key' }),
                    // rtc_token_provider: () => Promise.resolve({ token: 'signed-JWT-token' }),
                    // rtc_document_id: documentID,
                    // rtc_encryption_provider: ({ documentId, keyHint }) =>
                    //     fetch('https://api.example/getEncryptionKey/', {
                    //         method: 'POST',
                    //         credentials: 'include',
                    //         headers: {
                    //             'Accept': 'application/json',
                    //             'Content-Type': 'application/json',
                    //         },
                    //         body: JSON.stringify({ documentId, keyId: keyHint }),
                    //     })
                    //         .then((response) => response.json())
                    //         .catch((error) =>
                    //             console.log('Failed to return encryption key\n' + error)
                    //         ),
                    // rtc_token_provider: () =>
                    //     fetch('http://localhost:1337/jwt', {
                    //         method: 'POST',
                    //         // credentials: 'include',
                    //         headers: {
                    //             'Accept': 'application/json',
                    //             'Content-Type': 'application/json',
                    //         },
                    //         body: JSON.stringify({ userId }),
                    //     })
                    //         .then((response) => response.json())
                    //         .catch((error) => console.log('Failed to return a JWT\n' + error)),
                    // rtc_user_details_provider: ({ userId }) =>
                    //     fetch('http://localhost:1337/user-details', {
                    //         method: 'POST',
                    //         headers: {
                    //             'Accept': 'application/json',
                    //             'Content-Type': 'application/json',
                    //         },
                    //         body: JSON.stringify({ userId }),
                    //     })
                    //         .then((response) => response.json()),
                    // rtc_initial_content_provider: () =>
                    //     Promise.resolve({ content: initialEditorContent }),
                    // rtc_client_connected: ({
                    //     userDetails,
                    //     userId,
                    //     caretNumber,
                    //     clientId,
                    // }) => {
                    //     connectedUsers[clientId] = {
                    //         caretNumber,
                    //         userDetails,
                    //         userId,
                    //     };
                    //     /* Adds the collaborator above the editor and logs details to the console */
                    //     // document.getElementById(collaboratorUsernameElem).innerText =
                    //     //     userDetails.fullName;
                    //     console.log(
                    //         `Fake user ${userDetails.fullName} (${userDetails.email}) connected with caret number ${caretNumber}`
                    //     );
                    // },
                    // rtc_client_disconnected: ({ clientId, userDetails }) => {
                    //     delete connectedUsers[clientId];
                    //     /* Removes collaborator from above the editor and logs to the console */
                    //     // document.getElementById(collaboratorUsernameElem).innerText =
                    //     //     userDetails.fullName;
                    //     console.log(
                    //         `Fake user ${userDetails.fullName} (${userDetails.email}) disconnected`
                    //     );
                    // },
                }}
            />
            <button onClick={log}>Log editor content</button>

        </>
    );
}