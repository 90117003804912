import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';
//import '@fontsource/montserrat';

// Create a theme instance.
let baseTheme = createTheme({
  palette: {
    primary: {
      main: '#355C7D',
    },
    secondary: {
      main: '#ff3399', //ff3399: pink, ff9800: orange
      contrastText: '#ffffff'
    },
    error: {
      main: red.A400,
    },
    background: {
        default: '#f2f2f2'
    },
  },
  typography: {
      fontFamily: [
          'Montserrat',
          'sans-serif',
      ].join(','),
      fontSize: 12
  },
});

export const theme = responsiveFontSizes(baseTheme);