import React from 'react';
import { Home } from "../pages/Home/Home";
import { Routes, Route } from "react-router-dom";
import { Collateral } from '../pages/Collateral/Collateral';
import { CollateralList } from '../pages/Collateral/CollateralList';
import { Sign } from '../pages/eSign/Sign';

function PageRouting() {
    return (
        <Routes>
            <Route path="/" element={<CollateralList />} />
            <Route path="/collateral" element={<Collateral />} />
            <Route path="/collaterals" element={<CollateralList />} />
            <Route path="/esignature" element={<Sign />} />
        </Routes>
    )
}

export default PageRouting;