import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ContentEditor } from '../../components/content-editor/ContentEditor';
import { Alert, Button, List, ListItem, Grid, ListItemText, ListItemButton, Chip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecommendationsByClauseId, selectRecommendationsByClauseId } from './CollateralSlice';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { Check } from '@mui/icons-material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const sectionRecommendations = [
    {
        providerId: 20000001,
        providerName: 'Oliva Lou Allen',
        contentDiff: '[{"action":"modifyTextElement","route":[3,7,0,0],"oldValue":"Failure to abide by any of this Contract&rsquo;s provisions may result in termination of this Contract.","newValue":"Failure to abide by any of this Contract&rsquo;s provisions may result in "},{"action":"addElement","route":[3,7,0,1],"element":{"nodeName":"SPAN","attributes":{"class":"mce-annotation tox-comment","data-mce-annotation-uid":"mce-conversation_67980413531665636089334","data-mce-annotation":"tinycomments"},"childNodes":[{"nodeName":"#text","data":"appropriate penalties"}]}},{"action":"addTextElement","route":[3,7,0,2],"value":"."}]',
        comments: 'tinycomments|2.1|data:application/json;base64,eyJtY2UtY29udmVyc2F0aW9uXzY3OTgwNDEzNTMxNjY1NjM2MDg5MzM0Ijp7InVpZCI6Im1jZS1jb252ZXJzYXRpb25fNjc5ODA0MTM1MzE2NjU2MzYwODkzMzQiLCJjb21tZW50cyI6W3sidWlkIjoibWNlLWNvbnZlcnNhdGlvbl82Nzk4MDQxMzUzMTY2NTYzNjA4OTMzNCIsImF1dGhvciI6IkFub24iLCJhdXRob3JOYW1lIjoiQW5vbiIsImNvbnRlbnQiOiJDaGFuZ2VkIHRlcm1pbmF0aW9uIGNsYXVzZSB0byBwZW5hbHR5LiIsImNyZWF0ZWRBdCI6IjIwMjItMTAtMTNUMDQ6NDE6MjkuMzM0WiIsIm1vZGlmaWVkQXQiOiIyMDIyLTEwLTEzVDA0OjQxOjI5LjMzNFoifV19fQ=='
    }
];

function GetDiff(diff) {
    return (
        <Grid container spacing={4} pt={1}>
            <Grid item>MODIFY</Grid>
            <Grid item>{diff.diff.oldValue}</Grid>
        </Grid>
    )
}

export function CollateralSection({ subSections, selectedSubSectionIndex }) {
    const dispatch = useDispatch();

    const [value, setValue] = React.useState(selectedSubSectionIndex || 0);
    const [recommendations, setRecommendations] = React.useState(sectionRecommendations);
    const [selectedRecommendation, setSelectedRecommendation] = React.useState(null);
    const [showRecommendations, setShowRecommendations] = React.useState(false);

    const [clauseRecommendations, setClauseRecommendations] = useState([]);
    const [currentClause, setCurrentClause] = useState('');

    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue);
    };

    const handleFetchRecommendations = async (elemId, clause) => {
        const result = await dispatch(fetchRecommendationsByClauseId(elemId));
        setClauseRecommendations(result.payload.recommendations);
        setCurrentClause(clause);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="Vertical tabs example"
                >
                    {
                        subSections.map((s, i) => <Tab key={s.id} label={s.label} {...a11yProps(i)} />)
                    }
                </Tabs>
            </Box>
            {
                subSections.map((s, i) => (
                    <TabPanel key={s.id} value={value} index={i}>
                        {/* <Alert sx={{ mb: showRecommendations ? 0 : 2 }} severity="info">Based on previously negotiated contracts, we have some recommendations available for you. <Button onClick={() => setShowRecommendations(true)}>See recommendations</Button></Alert> */}

                        {/* {
                            showRecommendations && <Box>
                                <List>
                                    {
                                        recommendations.map(r => (
                                            <ListItem key={r.providerId}
                                                secondaryAction={
                                                    <Button variant="outlined" color="secondary" onClick={() => setSelectedRecommendation(r)}>Apply</Button>
                                                }>
                                                <ListItemButton>

                                                    <ListItemText primary={r.providerName} secondary={
                                                        <GetDiff diff={JSON.parse(r.contentDiff)[0]} />
                                                    }>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>
                        } */}

                        {
                            clauseRecommendations?.length > 0 && <Alert sx={{ mb: showRecommendations ? 0 : 2 }} severity="info">
                                Based on previously negotiated contracts, we have some recommendations available for you.
                                <Button onClick={() => setShowRecommendations(true)}>See recommendations</Button>
                            </Alert>
                        }

                        {/* {
                            showRecommendations && <Box>
                                <List>
                                    {
                                        clauseRecommendations.map((r, i) => (
                                            <ListItem key={r.providerId}>
                                                <ListItemText primary={<Grid container alignItems={"center"} spacing={2}>
                                                    <Grid item>{`${i + 1}. ${r.providerName}`}</Grid>
                                                    <Grid item><Chip label={`Form: ${r.formName}`} /></Grid>
                                                    <Grid item><Chip label={`Version: ${r.formVersion}`} /></Grid>
                                                </Grid>} secondary={<ReactDiffViewer oldValue={currentClause} newValue={r.text} splitView={true} />}>
                                                </ListItemText>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>
                        } */}

                        {
                            showRecommendations && <Box py={2}>
                                <Grid container flexDirection={"column"} spacing={2}>
                                    {
                                        clauseRecommendations.map((r, i) => (
                                            <Grid item key={r.providerId} sx={{ margin: 2, padding: 2, border: 'dotted 1px #ccc' }}>
                                                <Grid container alignItems={"center"} spacing={2} pb={1}>
                                                    <Grid item><Typography sx={{ fontWeight: "bold" }} variant="subtitle1">{`${i + 1}. ${r.providerName}`}</Typography></Grid>
                                                    <Grid item><Chip color="info" variant="outlined" label={`NPI: ${r.npiNumber}`} /></Grid>
                                                    <Grid item><Chip icon={<Check />} color="success" variant="outlined" label={`Approved By: ${r.approvedBy} on ${r.approvedDate}`} /></Grid>
                                                    <Grid item><Chip label={`Form: ${r.formName}`} /></Grid>
                                                    <Grid item><Chip label={`Version: ${r.formVersion}`} /></Grid>
                                                </Grid>
                                                <ReactDiffViewer oldValue={currentClause} newValue={r.text}
                                                    splitView={true} extraLinesSurroundingDiff={0} hideLineNumbers={true}
                                                    showDiffOnly={true}
                                                    compareMethod={DiffMethod.WORDS}
                                                    leftTitle="Current Clause" rightTitle="Recommended Clause" />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Box>
                        }

                        <ContentEditor content={s.content} recommendation={selectedRecommendation}
                            fetchRecommendations={handleFetchRecommendations}
                        />
                    </TabPanel>
                ))
            }

        </Box>
    );
};