import { useEffect, useState } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./../../authConfig-azuread-b2c";
import { useQuery } from "./../../hooks/useQuery";
import NavBar from "./../nav/NavBar";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const PageLayout = (props) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    let query = useQuery();
    let navigate = useNavigate();

    const [showNav, setShowNav] = useState(false);

    useEffect(() => {
        if (!query)
            return;

        setShowNav(query.get("headless") !== "true");

        if (query.get("collateral") === "true") {
            if (query.get("headless") === "true")
                navigate(`/collateral?headless=true`);
            else
                navigate(`/collateral`);
        } else if (query.get("esign") === "true") {
            if (query.get("headless") === "true")
                navigate(`/esignature?headless=true`);
            else
                navigate(`/esignature`);
        }
    }, [query])

    useEffect(() => {
        if (isAuthenticated) return;

        if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect)
            instance.loginRedirect(loginRequest);
    }, [isAuthenticated, inProgress, instance]);

    return (
        <>
            {showNav && <NavBar />}
            <Box px={2} py={2}>
                {props.children}
            </Box>
        </>
    );
};