import {
    Save, Close
} from '@mui/icons-material';
import {
    Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@mui/material";
import React from 'react';
import { CollateralSection } from './CollateralSection';
import { useSelector } from 'react-redux';
import { selectSectionToEdit, selectSectionById } from './CollateralSlice';

export function EditSection({ s, selectedSubSectionIndex, closeHandler }) {
    const sectionToEdit = useSelector(selectSectionToEdit);
    const section = useSelector((state) => selectSectionById(state, sectionToEdit.id));

    // return (
    //     <CollateralSection subSections={s.subSections} selectedSubSectionIndex={selectedSubSectionIndex} />
    // );


    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={"xl"}
            onClose={closeHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {s.label}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please review the content and make changes as necessary.
                </DialogContentText>
                <Box pt={1}>
                    <CollateralSection subSections={s.subSections} selectedSubSectionIndex={selectedSubSectionIndex} />
                </Box>
            </DialogContent>
            <DialogActions sx={{ margin: 1 }}>
                <Button variant="outlined" onClick={closeHandler} startIcon={<Close />}>Cancel</Button>
                <Button variant="contained" onClick={closeHandler} autoFocus startIcon={<Save />}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};