import {
    Box, Grid, List, ListSubheader
} from "@mui/material";
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { CollapsibleSectionIndex } from './CollapsibleSectionIndex';

export const SectionsIndex = ({ sections }) => {
    return (
        <Box sx={{
            bgcolor: 'background.paper', border: "solid 1px #ccc",
            borderRadius: "0.25rem", height: "98vh", position: "sticky", top: 0,
            //overflowY: "scroll"
        }}>
            <PerfectScrollbar>
                <List component={"nav"}
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader"
                            sx={{ borderRadius: "0.25rem", borderBottom: "dotted 1px #ccc" }}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item>SECTIONS INDEX</Grid>
                                <Grid item>
                                    {/* <IconButton>
                                        <Upgrade color="action" />
                                    </IconButton> */}
                                </Grid>
                            </Grid>
                        </ListSubheader>
                    }>
                    {
                        sections.map((s, index) => (
                            <CollapsibleSectionIndex key={s.id} section={s} index={index} />
                        ))
                    }
                </List>
            </PerfectScrollbar>
        </Box >
    );
};