import { configureStore } from '@reduxjs/toolkit';
import SignDocumentReducer from './../features/eSign/SignDocument/SignDocumentSlice';
import CollateralReducer from './../features/collateral/CollateralSlice';

export const store = configureStore({
  reducer: {
    signDoc: SignDocumentReducer,
    collateral: CollateralReducer
  },
});
