import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Paper, IconButton, Button, Icon } from "@mui/material";
import { faHospitalUser, faSearch, faExternalLinkAlt, faSignature, faGripVertical, faGripLines, faGripHorizontal } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';

export const DragabbleWidget = ({ title, faIcon, rightAction, helperText, placeholder, elevation, width, searchHandler }) => {
    const [npi, setNpi] = useState("");

    return (
        <Box component="div" sx={{ display: 'flex', alignItems: 'center', cursor: "grab" }}>
            <Paper
                component="form" elevation={elevation || 2}
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: width || 425 }}
            >
                <Button variant="text" fullWidth
                    startIcon={<FontAwesomeIcon icon={faSignature} />}>
                    Add signature
                </Button>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <Icon color="primary" sx={{ p: '10px' }} aria-label="directions">
                    <FontAwesomeIcon icon={faGripHorizontal} color="#355c7d" size="xs" />
                </Icon>
            </Paper>
        </Box>
    );
};