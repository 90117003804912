import React, { } from 'react';
import {
    Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography
} from "@mui/material";
import { Close, DragHandle } from '@mui/icons-material';
import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable } from 'array-move';

export function ReorderSigners({ signers, onChangeHandler, closeHandler }) {
    const onDrop = ({ removedIndex, addedIndex }) => {
        onChangeHandler(items => arrayMoveImmutable(items, removedIndex, addedIndex));
    };

    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={"xs"}
            onClose={closeHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Reorder Signers
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please use the drag handle to reorder signers. eSign requests will be issued in the order listed here.
                </DialogContentText>
                <Box pt={1}>
                    <List>
                        <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                            {signers.map(({ id, name, email, role }) => (
                                <Draggable key={id}>
                                    <ListItem>
                                        <ListItemText primary={
                                            <Box sx={{ display: "flex", columnGap: 2 }}>
                                                <Typography>{name}</Typography>
                                                <Chip label={role} color="info" variant='outlined' size="small" />
                                            </Box>
                                        } secondary={email} />
                                        <ListItemSecondaryAction>
                                            <ListItemIcon className="drag-handle">
                                                <DragHandle />
                                            </ListItemIcon>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Draggable>
                            ))}
                        </Container>
                    </List>
                </Box>
            </DialogContent>
            <DialogActions sx={{ margin: 1 }}>
                <Button variant="text" onClick={closeHandler} startIcon={<Close />}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}