import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import { AccessTime, TurnedInNot, WatchOutlined } from "@mui/icons-material";
import {
    Backdrop,
    Box, Button, Chip, Grid, Stack, Typography
} from "@mui/material";
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Element } from 'react-scroll'
import { PuffLoader, PropagateLoader } from "react-spinners";
import Typist from "react-typist";
import { PageTitle } from "../../components/layout/PageTitle";
import formInstanceDesignData from '../../data/GetFormInstanceDesignData_Collateral.json';
import { SectionsIndex } from "./SectionsIndex";
import { ShowSubSection } from './ShowSubSection';
import { useSelector } from 'react-redux';
import { selectCollateralFormInstance } from './CollateralSlice';

const override = {
    borderColor: "red",
};

function LoadingBackdrop() {
    return (
        <Box>
            <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>

                <Stack sx={{ alignItems: "center", rowGap: '0.5rem' }}>
                    <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>

                        <AccessTime fontSize="large" />
                        <Typography variant="h5">Please wait while we process your request...</Typography>
                    </Box>
                    <PropagateLoader color="#36d7b7" loading={true} cssOverride={override} />
                </Stack>

            </Backdrop>
        </Box>
    );
}

export const CollateralView = () => {
    const [sections, setSections] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const formInstance = useSelector(selectCollateralFormInstance);

    useEffect(() => {
        if (!formInstance) return;

        console.log(formInstance);

        const map = formInstance.Sections.map(s => {
            // TODO: Order sections and subsections by Sequence number. Check visibility and enablement checks.
            return {
                id: s.ID,
                name: s.GeneratedName,
                label: s.Label,
                subSections: s.Elements.filter(el => !el.GeneratedName.startsWith("ManualOverride")
                && !el.GeneratedName.startsWith("SimplifyRelationbetweenSimplifyandtheInstitution")).map(el => {
                    return {
                        id: el.ElementID,
                        name: el.GeneratedName,
                        label: el.Label,
                        content: formInstance.JSONData[s.GeneratedName][el.GeneratedName]
                    }
                })
            }
        });

        setSections(map);

    }, [formInstance])

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 500);
    }, []);

    return (
        isLoading ? <LoadingBackdrop /> :
            <Box>
                <PageTitle
                    title={formInstanceDesignData.FormName}
                    faIcon={faFileContract}
                    helperText={`Please review all sections of the contract and make changes as necessary. Please note that the
                    change proposals made here will flow into the actual contract only upon approval by the Payor.`}
                    chips={[
                        <Chip color="info" variant="outlined" label={"Olivia Lou Allen - 1265564876 - 2022 HC"} />,
                        <Chip label={`Version: ${formInstanceDesignData.FormVersion}`} />,
                        // <Chip label={`Last Updated: ${formInstanceDesignData.UpdatedDate}`} />
                        // <Chip label={`Last Updated: 10/12/2022 15:32:26`} />
                    ]}
                    rightAction={<Button color="secondary" variant="outlined" startIcon={<TurnedInNot />}>Submit</Button>}
                    sx={{ pb: 3 }}
                />
                {/* <Grid container direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Grid item>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Typography variant="h4">{formInstanceDesignData.FormName}</Typography></Grid>
                        <Grid item>
                            <Chip label={`Version: ${formInstanceDesignData.FormVersion}`} />
                        </Grid>
                        <Grid item>
                            <Chip label={`Last Updated: ${formInstanceDesignData.UpdatedDate}`} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button color="secondary" variant="outlined" startIcon={<TurnedInNot />}>Submit</Button>
                </Grid>
            </Grid>
            <Typography varaint="body1" pb={3}>Please review all sections of the contract and make changes as necessary. Please note that the
                change proposals made here will flow into the actual contract only upon approval by the Payor.</Typography> */}
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <SectionsIndex sections={sections} />
                    </Grid>
                    <Grid item xs={9}>
                        <Box id="sections-contaniner">
                            {
                                sections.map((s, si) => (
                                    <Element key={s.id} name={s.id.toString()}>
                                        {
                                            s.subSections.map((ss, ssi) => (
                                                <Element key={ss.id} name={ss.id.toString()}>
                                                    <Box mb={4}>
                                                        <ShowSubSection formName={formInstanceDesignData.FormName}
                                                            section={s} subSection={ss} sectionIndex={si} subSectionIndex={ssi}
                                                        />
                                                    </Box>
                                                </Element>
                                            ))
                                        }
                                    </Element>
                                ))
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
    );
};