import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Chip, Collapse, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from "@mui/material";
import WebViewer from '@pdftron/webviewer';
import './PrepareDocument.css';
import { ArrowBackIos, ArrowForwardIos, Cancel, CancelOutlined, Close, Delete, Download, ExpandLess, ExpandMore, Send, TurnedIn } from '@mui/icons-material';
import { SignDocument } from '../SignDocument/SignDocument';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCalendar, faFilePdf, faReorder, faSignature, faTextWidth, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Signers } from '../Signers/Signers';
import { ReorderSigners } from '../Signers/ReorderSigners';
import { green, orange } from '@mui/material/colors';
import { PDFEditor } from '../../../components/pdf-editor/PDFEditor';
import { PageTitle } from '../../../components/layout/PageTitle';
import { useMsal } from "@azure/msal-react";
import moment from "moment";
import { DragabbleWidget } from '../DraggableWidget/DragabbleWidget';

export const PrepareDocument = () => {
    const msalInstance = useMsal().instance;
    const activeAccount = msalInstance.getActiveAccount();

    const dispatch = useDispatch();

    const [instance, setInstance] = useState(null);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [dropPoint, setDropPoint] = useState(null);

    const [expandInfo, setExpandInfo] = useState(true);
    const [expandAttachments, setExpandAttachments] = useState(false);
    const [expandSigners, setExpandSigners] = useState(false);
    const [expandPrep, setExpandPrep] = useState(false);

    const [signers, setSigners] = useState([
        {
            id: 'olivia.allen@getwellsoonphysicians.com',
            name: 'Olivia Lou Allen',
            email: 'olivia.allen@getwellsoonphysicians.com',
            title: 'Counselor - Mental Health',
            order: 1,
            role: 'Provider',
            status: 'pending'
        }
    ]);
    const [signer, setSigner] = useState(null);
    const [showSigningReorder, setShowSigningReorder] = useState(false);

    useEffect(() => {
        if (activeAccount && !signers.some(s => s.id === activeAccount.username)) {
            const newSigner = {

                id: activeAccount.username,
                name: activeAccount.name,
                email: activeAccount.username,
                title: 'Admin',
                order: 2,
                role: 'Payor',
                status: 'pending'
            };

            setSigners([...signers, newSigner]);
        }
    }, []);

    const handleSignerSelection = (val) => {
        setSigner(val);
        setExpandPrep(true);;
    }

    const viewer = useRef(null);
    const filePicker = useRef(null);

    // if using a class, equivalent of componentDidMount
    useEffect(() => {
        WebViewer(
            {
                //fullAPI: true,
                path: 'webviewer',
                disabledElements: [
                    // 'ribbons',
                    // 'toggleNotesButton',
                    // 'searchButton',
                    'menuButton',
                ],
            },
            viewer.current,
        ).then(instance => {
            const { iframeWindow } = instance;
            const { PDFNet, documentViewer } = instance.Core;

            // select only the view group
            instance.setToolbarGroup('toolbarGroup-View');
            instance.setFitMode(instance.UI.FitMode.FitWidth);
            instance.setActiveLeftPanel(true);
            console.log(instance);

            setInstance(instance);

            const URL = "/Allen Olivia Lou-1265564878-10122022.Pdf";
            instance.loadDocument(URL);

            const iframeDoc = iframeWindow.document.body;
            iframeDoc.addEventListener('dragover', dragOver);
            iframeDoc.addEventListener('drop', e => {
                drop(e, instance);
            });

            filePicker.current.onchange = e => {
                const file = e.target.files[0];
                if (file) {
                    instance.loadDocument(file);
                }
            };

            // documentViewer.addEventListener('documentLoaded', async () => {
            //     await PDFNet.initialize();
            //     const doc = await documentViewer.getDocument().getPDFDoc();

            //     // Run PDFNet methods with memory management
            //     await PDFNet.runWithCleanup(async () => {

            //         // lock the document before a write operation
            //         // runWithCleanup will auto unlock when complete
            //         doc.lock();

            //         // Retrieve the unsigned certification signature field.
            //         /** * Note: Replace certificationFieldName with the field name in the * document that is being certified */
            //         const foundCertificationField = await doc.getField("employee.signaturexx");
            //         console.log("foundCertificationField", foundCertificationField);

            //         const text = await doc.loadPageText(1);
            //         // .. do something with text
            //         console.log(text);
            //     })
            // });
        });
    }, []);

    const applyFields = async () => {
        const { Annotations, docViewer } = instance;
        const annotManager = docViewer.getAnnotationManager();
        const fieldManager = annotManager.getFieldManager();
        const annotationsList = annotManager.getAnnotationsList();
        const annotsToDelete = [];
        const annotsToDraw = [];

        await Promise.all(
            annotationsList.map(async (annot, index) => {
                let inputAnnot;
                let field;

                if (typeof annot.custom !== 'undefined') {
                    // create a form field based on the type of annotation
                    if (annot.custom.type === 'TEXT') {
                        field = new Annotations.Forms.Field(
                            annot.getContents() + Date.now() + index,
                            {
                                type: 'Tx',
                                value: annot.custom.value,
                            },
                        );
                        inputAnnot = new Annotations.TextWidgetAnnotation(field);
                    } else if (annot.custom.type === 'SIGNATURE') {
                        field = new Annotations.Forms.Field(
                            annot.getContents() + Date.now() + index,
                            {
                                type: 'Sig',
                            },
                        );
                        inputAnnot = new Annotations.SignatureWidgetAnnotation(field, {
                            appearance: '_DEFAULT',
                            appearances: {
                                _DEFAULT: {
                                    Normal: {
                                        data:
                                            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
                                        offset: {
                                            x: 100,
                                            y: 100,
                                        },
                                    },
                                },
                            },
                        });
                    } else if (annot.custom.type === 'DATE') {
                        field = new Annotations.Forms.Field(
                            annot.getContents() + Date.now() + index,
                            {
                                type: 'Tx',
                                value: 'm-d-yyyy',
                                // Actions need to be added for DatePickerWidgetAnnotation to recognize this field.
                                actions: {
                                    F: [
                                        {
                                            name: 'JavaScript',
                                            // You can customize the date format here between the two double-quotation marks
                                            // or leave this blank to use the default format
                                            javascript: 'AFDate_FormatEx("mmm d, yyyy");',
                                        },
                                    ],
                                    K: [
                                        {
                                            name: 'JavaScript',
                                            // You can customize the date format here between the two double-quotation marks
                                            // or leave this blank to use the default format
                                            javascript: 'AFDate_FormatEx("mmm d, yyyy");',
                                        },
                                    ],
                                },
                            },
                        );

                        inputAnnot = new Annotations.DatePickerWidgetAnnotation(field);
                    } else {
                        // exit early for other annotations
                        annotManager.deleteAnnotation(annot, false, true); // prevent duplicates when importing xfdf
                        return;
                    }
                } else {
                    // exit early for other annotations
                    return;
                }

                // set position
                inputAnnot.PageNumber = annot.getPageNumber();
                inputAnnot.X = annot.getX();
                inputAnnot.Y = annot.getY();
                inputAnnot.rotation = annot.Rotation;
                if (annot.Rotation === 0 || annot.Rotation === 180) {
                    inputAnnot.Width = annot.getWidth();
                    inputAnnot.Height = annot.getHeight();
                } else {
                    inputAnnot.Width = annot.getHeight();
                    inputAnnot.Height = annot.getWidth();
                }

                // delete original annotation
                annotsToDelete.push(annot);

                // customize styles of the form field
                Annotations.WidgetAnnotation.getCustomStyles = function (widget) {
                    if (widget instanceof Annotations.SignatureWidgetAnnotation) {
                        return {
                            border: '1px solid #a5c7ff',
                        };
                    }
                };
                Annotations.WidgetAnnotation.getCustomStyles(inputAnnot);

                // draw the annotation the viewer
                annotManager.addAnnotation(inputAnnot);
                fieldManager.addField(field);
                annotsToDraw.push(inputAnnot);
            }),
        );

        // delete old annotations
        annotManager.deleteAnnotations(annotsToDelete, null, true);

        // refresh viewer
        await annotManager.drawAnnotationsFromList(annotsToDraw);
        await uploadForSigning();
    };

    const addField = (type, point = {}, name = '', value = '', flag = {}) => {
        const { docViewer, Annotations } = instance;
        const annotManager = docViewer.getAnnotationManager();
        const doc = docViewer.getDocument();
        const displayMode = docViewer.getDisplayModeManager().getDisplayMode();
        const page = displayMode.getSelectedPages(point, point);
        if (!!point.x && page.first == null) {
            return; //don't add field to an invalid page location
        }
        const page_idx =
            page.first !== null ? page.first : docViewer.getCurrentPage();
        const page_info = doc.getPageInfo(page_idx);
        const page_point = displayMode.windowToPage(point, page_idx);
        
        const zoom = docViewer.getZoom();

        var textAnnot = new Annotations.FreeTextAnnotation();
        textAnnot.PageNumber = page_idx;
        const rotation = docViewer.getCompleteRotation(page_idx) * 90;
        textAnnot.Rotation = rotation;
        if (rotation === 270 || rotation === 90) {
            textAnnot.Width = 50.0 / zoom;
            textAnnot.Height = 250.0 / zoom;
        } else {
            textAnnot.Width = 250.0 / zoom;
            textAnnot.Height = 50.0 / zoom;
        }
        // textAnnot.X = (page_point.x || page_info.width / 2) - textAnnot.Width / 2;
        // textAnnot.Y = (page_point.y || page_info.height / 2) - textAnnot.Height / 2;
        // TODO: New annotation placement
        textAnnot.X = 20;
        textAnnot.Y = 20;

        textAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));
        textAnnot.custom = {
            type,
            value,
            flag,
            //name: `${assignee}_${type}_`,
            name: `${signer.email}_${type}_`,
        };

        // set the type of annot
        textAnnot.setContents(textAnnot.custom.name);
        textAnnot.FontSize = '' + 14.0 / zoom + 'px';
        textAnnot.FillColor = new Annotations.Color(211, 211, 211, 0.5);
        textAnnot.TextColor = new Annotations.Color(0, 165, 228);
        textAnnot.StrokeThickness = 1;
        textAnnot.StrokeColor = new Annotations.Color(0, 165, 228);
        textAnnot.TextAlign = 'center';

        textAnnot.Author = annotManager.getCurrentUser();

        annotManager.deselectAllAnnotations();
        annotManager.addAnnotation(textAnnot, true);
        annotManager.redrawAnnotation(textAnnot);
        annotManager.selectAnnotation(textAnnot);
    };

    const uploadForSigning = async () => {
        // upload the PDF with fields as AcroForm
        // const storageRef = storage.ref();
        // const referenceString = `docToSign/${uid}${Date.now()}.pdf`;
        // const docRef = storageRef.child(referenceString);
        const { docViewer, annotManager } = instance;
        const doc = docViewer.getDocument();
        const xfdfString = await annotManager.exportAnnotations({ widgets: true, fields: true });
        const data = await doc.getFileData({ xfdfString });
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: 'application/pdf' });

        //console.log(blob);
        setPdfBlob(blob);

        // docRef.put(blob).then(function (snapshot) {
        //     console.log('Uploaded the blob');
        // });

        // create an entry in the database
        // const emails = assignees.map(assignee => {
        //     return assignee.email;
        // });
        // await addDocumentToSign(uid, email, referenceString, emails);
        // dispatch(resetSignee());
        // navigate('/');
    };

    const dragOver = e => {
        e.preventDefault();
        return false;
    };

    const drop = (e, instance) => {
        const { docViewer } = instance;
        const scrollElement = docViewer.getScrollViewElement();
        const scrollLeft = scrollElement.scrollLeft || 0;
        const scrollTop = scrollElement.scrollTop || 0;
        setDropPoint({ x: e.pageX + scrollLeft, y: e.pageY + scrollTop });
        e.preventDefault();
        return false;
    };

    const dragStart = e => {
        e.target.style.opacity = 0.5;
        const copy = e.target.cloneNode(true);
        copy.id = 'form-build-drag-image-copy';
        copy.style.width = '250px';
        document.body.appendChild(copy);
        e.dataTransfer.setDragImage(copy, 125, 25);
        e.dataTransfer.setData('text', '');
    };

    const dragEnd = (e, type) => {
        addField(type, dropPoint);
        e.target.style.opacity = 1;
        document.body.removeChild(
            document.getElementById('form-build-drag-image-copy'),
        );
        e.preventDefault();
    };

    if (pdfBlob) {
        return (
            <SignDocument blob={pdfBlob} />
        );
    }

    return (
        <div className={'prepareDocument'}>
            <PageTitle
                title={"Prepare Document"}
                faIcon={faSignature}
                helperText={`Please review all sections of the document and electronically sign it.`}
                chips={[
                    <Chip label={`Version: 1.0`} />
                ]}
                rightAction={
                    <Grid container spacing={2}>
                        <Grid item sx={{ ml: 4 }}>
                            <Button color="primary" variant="outlined" startIcon={<Close />}
                            >Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" variant="contained" startIcon={<Send />} onClick={applyFields}
                            >Send for Signing</Button>
                        </Grid>
                    </Grid>
                }
                sx={{ pb: 3 }}
            />
            <Grid container spacing={2}>
                <Grid item xs={3}>

                    <Box sx={{
                        bgcolor: 'background.paper', border: "solid 1px #ccc",
                        borderRadius: "0.25rem", mb: 3
                        //overflowY: "scroll"
                    }}>
                        <Box py={1} px={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                <Typography variant="h6">PHO Agreement</Typography>
                                <Typography variant="caption">Physician Hospital Agreement</Typography>
                            </Box>
                            <IconButton onClick={() => setExpandInfo(!expandInfo)}>
                                {expandInfo ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>
                        <Collapse in={expandInfo} timeout="auto" unmountOnExit>
                            <Divider />
                            <Box>

                                <Typography variant="subtitle1" color="primary" sx={{ p: 2 }}>Agreement between Olivia Lou Allen and Simplify Healthcare.</Typography>
                                <List dense >
                                    {
                                        [
                                            "Physician Hospital Agreement"
                                        ].map(a => (
                                            <ListItem key={a} disableGutters>
                                                <ListItemButton>
                                                    <ListItemIcon><FontAwesomeIcon icon={faFilePdf} /></ListItemIcon>
                                                    <ListItemText
                                                        primary={a} primaryTypographyProps={{ color: "#0288d1" }}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                                <TableContainer>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Version</TableCell>
                                                <TableCell>1.0</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Draft</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Requested By</TableCell>
                                                <TableCell>John Doe</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Requested On</TableCell>
                                                <TableCell>{moment().format("MMM Do, YYYY")}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Sign By</TableCell>
                                                <TableCell>{moment().add(30, 'days').format("MMM Do, YYYY")}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Typography variant="body2" sx={{ p: 2 }}>Please ensure all necessary attachments and included in the envelope
                                    prior to sending the document for signing.</Typography>
                            </Box>

                            {/* <Divider />
                            <Box py={2} px={2}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button onClick={applyFields} variant="contained" size="large" color="primary"
                                        startIcon={<Send />}>
                                        Send for signing
                                    </Button>
                                </Box>
                            </Box> */}
                        </Collapse>
                    </Box>

                    <Box sx={{
                        bgcolor: 'background.paper', border: "solid 1px #ccc",
                        borderRadius: "0.25rem", mb: 3
                        //overflowY: "scroll"
                    }}>
                        <Box py={1} px={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                <Typography variant="h6">Attachments</Typography>
                                <Typography variant="caption">Additional attachments included with the document.</Typography>
                            </Box>
                            <IconButton onClick={() => setExpandAttachments(!expandAttachments)}>
                                {expandAttachments ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>
                        <Collapse in={expandAttachments} timeout="auto" unmountOnExit>
                            <Divider />
                            <Box>
                                <List dense >
                                    {
                                        [
                                            "Policies and Procedures Manual",
                                            "Reimbursement Methodology",
                                            "Provider Information Form",
                                            "Networks and Products",
                                            "Delegation Agreement"
                                        ].map(a => (
                                            <ListItem key={a} disableGutters>
                                                <ListItemButton>
                                                    <ListItemIcon><FontAwesomeIcon icon={faFilePdf} /></ListItemIcon>
                                                    <ListItemText
                                                        primary={a} primaryTypographyProps={{ color: "#0288d1" }}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>

                            <Divider />
                            <Box py={2} px={2}>
                                <Box sx={{ display: "flex", columnGap: 2 }}>
                                    <Button variant="text" fullWidth
                                        startIcon={<FontAwesomeIcon icon={faUpload} />}>
                                        Upload New
                                    </Button>
                                </Box>
                            </Box>
                        </Collapse>
                    </Box>

                    <Box sx={{
                        bgcolor: 'background.paper', border: "solid 1px #ccc",
                        borderRadius: "0.25rem", mb: 3
                        //overflowY: "scroll"
                    }}>
                        <Box py={1} px={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                <Typography variant="h6">Assign Signers</Typography>
                                <Typography variant="caption">eSign requests will be issued in the order listed here.</Typography>
                            </Box>
                            <IconButton onClick={() => setExpandSigners(!expandSigners)}>
                                {expandSigners ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>
                        <Collapse in={expandSigners} timeout="auto" unmountOnExit>
                            <Divider />
                            {showSigningReorder && <ReorderSigners signers={signers} onChangeHandler={setSigners} closeHandler={() => setShowSigningReorder(false)} />}
                            <Box py={1}>
                                <Signers signers={signers} currentSigner={signer} onSelect={handleSignerSelection} />
                            </Box>
                            <Divider />
                            <Box py={2} px={2}>
                                <Box sx={{ display: "flex", columnGap: 2 }}>
                                    <Button variant="text" fullWidth
                                        startIcon={<FontAwesomeIcon icon={faAdd} />}>
                                        Add Signer
                                    </Button>
                                    <Button onClick={() => setShowSigningReorder(true)}
                                        variant="text" color="secondary" fullWidth
                                        startIcon={<FontAwesomeIcon icon={faReorder} />}>
                                        Reorder
                                    </Button>
                                </Box>
                            </Box>
                        </Collapse>
                    </Box>
                    <Box sx={{
                        bgcolor: 'background.paper', border: "solid 1px #ccc",
                        borderRadius: "0.25rem"
                        //overflowY: "scroll"
                    }}>
                        <Box py={1} px={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                <Typography variant="h6">
                                    Prepare Fields
                                    <Chip component={"span"} label={signer ? `for ${signer.name}` : 'Add/Select Signer'}
                                        sx={{ ml: 1, background: signer ? green[100] : "inherit" }} color={signer ? "success" : "warning"}
                                        variant='outlined' onClick={() => setExpandSigners(true)} />
                                </Typography>

                                <Typography variant="caption">Drag and drop to place controls on the document.</Typography>
                            </Box>
                            <IconButton onClick={() => setExpandPrep(!expandPrep)}>
                                {expandPrep ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>
                        <Collapse in={expandPrep} timeout="auto" unmountOnExit>
                            <Divider />
                            {/* <Button
                            onClick={() => {
                                if (filePicker) {
                                    filePicker.current.click();
                                }
                            }}
                            endIcon={<UploadFile />}
                        >Upload a document</Button> */}
                            <Box pt={2} pb={1} px={2}>
                                <div
                                    draggable
                                    onDragStart={e => dragStart(e)}
                                    onDragEnd={e => dragEnd(e, 'SIGNATURE')}
                                >
                                    <Tooltip title="Drag & drop Signature on document." placement="top">
                                        <Button onClick={() => addField('SIGNATURE')} variant="outlined" fullWidth
                                            sx={{ cursor: "grab" }}
                                            disabled={!signer}
                                            startIcon={<FontAwesomeIcon icon={faSignature} />}>
                                            Add signature
                                        </Button>
                                    </Tooltip>
                                </div>
                            </Box>
                            <Box py={1} px={2}>
                                <div
                                    draggable
                                    onDragStart={e => dragStart(e)}
                                    onDragEnd={e => dragEnd(e, 'TEXT')}
                                >
                                    <Tooltip title="Drag & drop Text on document." placement="top">
                                        <Button onClick={() => addField('TEXT')} variant="outlined" color="secondary" fullWidth
                                            disabled={!signer}
                                            startIcon={<FontAwesomeIcon icon={faTextWidth} />}>
                                            Add text
                                        </Button>
                                    </Tooltip>
                                </div>
                            </Box>
                            <Box pt={1} pb={2} px={2}>
                                <div
                                    draggable
                                    onDragStart={e => dragStart(e)}
                                    onDragEnd={e => dragEnd(e, 'DATE')}
                                >
                                    <Tooltip title="Drag & drop Date on document." placement="top">
                                        <Button onClick={() => addField('DATE')} variant="outlined" color="secondary" fullWidth
                                            disabled={!signer}
                                            startIcon={<FontAwesomeIcon icon={faCalendar} />}>
                                            Add date
                                        </Button>
                                    </Tooltip>
                                </div>
                            </Box>
                        </Collapse>
                    </Box>


                </Grid>
                <Grid item xs={9}>
                    <div className="webviewer" ref={viewer}></div>
                    {/* <PDFEditor /> */}
                    <input type="file" ref={filePicker} style={{ display: 'none' }} />
                </Grid>
            </Grid>

        </div>
    );
};