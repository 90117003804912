import { createSlice } from '@reduxjs/toolkit';

export const SignDocumentSlice = createSlice({
    name: 'signDoc',
    initialState: {
        // TODO: change back to null instead of object - docToSign: null,
        docToSign: {
            docRef: "docToSign/c4Y72M0d0pZx3476jxJFxrFA3Qo21593036106369.pdf",
            email: "andrey@email.com",
            emails: ["julia@email.com"],
            signed: true,
            signedBy: ["julia@email.com"],
            requestedTime: "July 17, 2020 at 12:01:24 PM UTC-7",
            signedTime: "July 17, 2020 at 12:01:24 PM UTC-7",
            uid: "c4Y72M0d0pZx3476jxJFxrFA3Qo2",
            xfdf: null
        },
    },
    reducers: {
        setDocToSign: (state, action) => {
            state.docToSign = action.payload;
        },
        resetDocToSign: (state, action) => {
            state.docToSign = null;
        }
    },
});

export const { setDocToSign, resetDocToSign } = SignDocumentSlice.actions;

export const selectDocToSign = state => state.signDoc.docToSign;

export default SignDocumentSlice.reducer;