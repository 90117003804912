import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Link as RouterLink } from "react-router-dom";
import { Box, IconButton, Menu, Tooltip, MenuItem } from '@mui/material';
import WelcomeName from "../security/WelcomeName";
import { AccountPicker } from "../security/AccountPicker";

const NavProfile = () => {
    const { instance } = useMsal();
    const [accountSelectorOpen, setOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleLogout = (logoutType) => {
        setAnchorEl(null);

        if (logoutType === "popup") {
            instance.logoutPopup();
        } else if (logoutType === "redirect") {
            instance.logoutRedirect();
        }
    }

    const handleAccountSelection = () => {
        setAnchorEl(null);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
                <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} sx={{ p: 0 }}>
                    <WelcomeName />
                </IconButton>
            </Tooltip>
            
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => setAnchorEl(null)} key="profile" component={RouterLink} to="/profileUseMsalAuthenticationHook">Profile</MenuItem>
                <MenuItem onClick={() => handleAccountSelection()} key="switchAccount">Switch Account</MenuItem>
                <MenuItem onClick={() => handleLogout("redirect")} key="logoutRedirect">Logout</MenuItem>
            </Menu>
            <AccountPicker open={accountSelectorOpen} onClose={handleClose} />
        </Box>
    );
};
export default NavProfile;
    