import React from 'react';
import {
    Avatar, Box, Chip, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction,
    ListItemText, Tooltip, Typography
} from "@mui/material";
import { green, lightGreen, red, yellow } from '@mui/material/colors';
import { CheckCircleOutlineOutlined, CloseOutlined, PendingActionsOutlined, WarningAmber } from '@mui/icons-material';

function SigningStatus({ status }) {

    let icon = null;
    switch (status) {
        case 'signed':
            icon = <CheckCircleOutlineOutlined color="success" />;
            break;
        case 'rejected':
            icon = <WarningAmber color="error" />;
            break;
        default:
            icon = <PendingActionsOutlined color="disabled" />;
            break;
    }

    return (
        <Tooltip title={status}>
            {icon}
        </Tooltip>
    );
}

export function Signers({ signers, currentSigner, onSelect }) {

    const selectionHandler = (signer) => {
        if (currentSigner && signer.id === currentSigner.id)
            onSelect(null);
        else
            onSelect(signer);
    }

    return (
        <List dense disablePadding>
            {
                signers?.map((s, i) => (
                    <ListItem key={s.id} alignItems="flex-start" disablePadding
                        sx={{ background: (s.id === currentSigner?.id ? green[100] : "inherit") }}>
                        <ListItemButton onClick={() => selectionHandler(s)}>
                            {/* <ListItemAvatar>
                                <Avatar sx={{ bgcolor: red[500], width: 28, height: 28 }}>
                                    {i + 1}
                                </Avatar>
                            </ListItemAvatar> */}
                            <ListItemText primary={
                                <Box sx={{ display: "flex", columnGap: 2 }}>
                                    <Typography>{`${s.name}`}</Typography>
                                    <Chip label={s.role} color="info" variant='outlined' size="small" />
                                </Box>
                            }
                                secondary={<Box component="span" sx={{fontSize: "0.7rem"}}>{s.email}</Box>} />
                            <ListItemSecondaryAction>
                                <SigningStatus status={s.status} />
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    </ListItem>
                ))
            }
        </List>
    );
};