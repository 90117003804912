import { useIsAuthenticated } from "@azure/msal-react";
import { AppBar as MuiAppBar, Box, Container, Divider, Stack, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import shLogo from './../../assets/images/eProviderSync.png'; // BCBSNE6Blue.png eProviderSync.png
import NavMenu from "./NavMenu";
import NavProfile from "./NavProfile";

function renderLogo() {
    return (
        <Stack
            component={RouterLink}
            to="/"
            sx={{ mr: { xs: 0, md: 1 }, display: 'flex', flexGrow: 1, alignItems: "flex-start", textDecoration: "none" }}
        >
            <Box
                component="img"
                sx={{
                    height: { xs: '1rem', md: '1rem' }, mr: { xs: 0, md: 1 }, objectFit: "scale-down",
                    marginLeft: "1rem"
                }}
                alt="Collateral"
                src={shLogo}
            />
            <Typography variant="subtitle2" noWrap sx={{
                padding: "0.25rem 0.95rem",
                fontWeight: "bold",
                background: "#f3c36a",
                borderRadius: "0.25rem",
                fontSize: "11px",
                fontColor: "#483d8b",
                marginLeft: "1rem"
            }}> .Collaborate</Typography>
        </Stack>
    );
}

const AppBar = () => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <MuiAppBar position="static" color="primary" elevation={0}>
            <Container maxWidth="xl" disableGutters>
                <Toolbar disableGutters>
                    <Box component="div" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Box sx={{ background: "#fff", padding: "11px 80px 11px 48px", clipPath: "polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%)" }}>
                            {renderLogo()}
                        </Box>
                        <Box sx={{ display: "flex", paddingRight: "1rem" }}>
                            <NavMenu />
                            {isAuthenticated && <NavProfile />}
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
            <Divider sx={{ display: "grid", placeItems: "center", borderBottom: "0.25rem solid",
                borderImage: "linear-gradient(to left, turquoise, greenyellow) 1 0"
         }} />
        </MuiAppBar>
    );
};
export default AppBar;
    