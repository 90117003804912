import { AuthenticatedTemplate } from "@azure/msal-react";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import { CheckCircleOutlineOutlined, ExpandMore, PendingActionsOutlined, WarningAmber } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Card, CardContent, CardHeader, Chip, Collapse, Grid, List, ListItem, ListItemButton, ListItemText, Tooltip, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { PageTitle } from "../../components/layout/PageTitle";

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    name = name === "unknown" ? "Unknown Unknown" : name;
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

function SigningStatus({ status }) {

    let icon = null;
    switch (status) {
        case 'signed':
            icon = <CheckCircleOutlineOutlined color="success" />;
            break;
        case 'rejected':
            icon = <WarningAmber color="error" />;
            break;
        default:
            icon = <PendingActionsOutlined color="disabled" />;
            break;
    }

    return (
        <Tooltip title={status}>
            {icon}
        </Tooltip>
    );
}

export const CollateralList = (props) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [providers, setProviders] = useState([
        {
            id: "1279-127979-928923-1223",
            name: "Olivia Lou Allen",
            npiType1: "1265564876",
            practicingAddress: {
                line1: "2330 GLENDALE LN STE 100",
                city: "SACRAMENTO",
                state: "CA",
                zip: "95825-2454"
            },
            progressCount: "1 of 2 completed",
            collaterals: [
                {
                    title: "Olivia Lou Allen - 1265564876 - 2022 DOC",
                    type: "DOC - Practitioner Contract",
                    status: "signed",
                    generatedOn: "10/12/2022 15:32:18"
                },
                {
                    title: "Olivia Lou Allen - 1265564876 - 2022 HC",
                    type: "HC - Practitioner Contract",
                    status: "pending",
                    generatedOn: "10/12/2022 15:32:26"
                }
            ]
        },
        // {
        //     id: "1279-127979-928923-1224",
        //     name: "Michael Aaron",
        //     npiType1: "1700958659",
        //     practicingAddress: {
        //         line1: "3743 LEGACY",
        //         city: "WEATHERFORD",
        //         state: "OK",
        //         zip: "73096-9746"
        //     },
        //     collaterals: [
        //         {
        //             title: "Michael Aaron - 1700958659 - 2022 HC",
        //             type: "HC - Practitioner Contract",
        //             status: "signed",
        //             generatedOn: "09/16/2022 09:11:52"
        //         },
        //         {
        //             title: "Michael Aaron - 1700958659 - 2022 DRS",
        //             type: "DRS - Practitioner Contract",
        //             status: "rejected",
        //             generatedOn: "09/16/2022 09:11:54"
        //         }
        //     ]
        // }
    ]);

    const launchCollateral = () => {
        navigate("/collateral");
    }

    return (
        <AuthenticatedTemplate>
            <Box>
                <PageTitle
                    title="Collaterals" faIcon={faFileContract}
                    tooltip="Contracts assigned for review"
                    helperText={"Sample helper text"}
                />
            </Box>

            <Box p={2}>
                <Typography variant="body1">Collaterals for the below Providers have been assigned to you.
                    Please review the documents and modify/eSign as necessary.</Typography>
            </Box>

            <Box p={2}>
                <Grid container spacing={4}>
                    {
                        providers.map(p => (
                            <Grid item key={p.id} xs={6}>
                                <Card>
                                    <CardHeader
                                        avatar={
                                            <Avatar {...stringAvatar(p.name)} />
                                        }
                                        title={<Typography variant="h6">{p.name}</Typography>}
                                        subheader={`NPI-1: ${p.npiType1}`}
                                        action={<Chip variant="outlined" color="info" label={p.progressCount} />}
                                    />
                                    <CardContent>
                                        <Typography variant="body2" color="text.secondary">
                                            The following collaterals are available for your review.
                                        </Typography>
                                        <List dense>
                                            {
                                                p.collaterals.map((c, i) => (
                                                    <ListItem key={i} dense>
                                                        <ListItemButton onClick={launchCollateral}>
                                                            <ListItemText primary={
                                                                <Box sx={{ display: "flex", columnGap: 2 }}>
                                                                    <Typography variant="subtitle2" color="primary">
                                                                        {c.title}
                                                                    </Typography>
                                                                    <Chip size="small" label={`Generated On: ${c.generatedOn}`} />
                                                                </Box>
                                                            } secondary={c.type}></ListItemText>
                                                        </ListItemButton>
                                                        <Typography variant="subtitle2" color="primary">
                                                            <SigningStatus status={c.status} />
                                                        </Typography>
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </AuthenticatedTemplate>
    );
};