import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import formInstanceDesignData from '../../data/GetFormInstanceDesignData_Collateral.json';

// First, create the thunk
export const fetchRecommendationsByClauseId = createAsyncThunk(
    'collateral/fetchRecommendationsByClauseId',
    async (clauseId, thunkAPI) => {
        const state = thunkAPI.getState(); // <-- invoke and access state object

        const section = state.collateral.sectionToEdit;
        const clauseKey = `${section.formName}:${section.sectionName}:${section.subSectionName}:${clauseId}`;

        // Check if recommendation already exists.
        const clause = state.collateral.recommendations.find(r => r.id === clauseKey);

        if (clause) {
            return clause;
        }

        const apiKey = "Jp9WICKuN4iBbBgxKdgEDnGGakdglFf3lKrjjiphALa1AzFuEXXCqQ%3D%3D";
        const url = `https://eps-clause-recommendation.azurewebsites.net/api/getrecommendations?code=${apiKey}&clauseKey=${clauseKey}`
        const apiResult = await fetch(url);
        const response = await apiResult.json();

        return {
            id: clauseKey,
            recommendations: response.data
        }
    }
)

export const CollateralSlice = createSlice({
    name: 'collateral',
    initialState: {
        // TODO: change back to null instead of object - formInstance: null,
        formInstance: {
            ...formInstanceDesignData,
            JSONData: JSON.parse(formInstanceDesignData.JSONData)
        },
        sectionToEdit: {
            id: null,
            formName: null,
            sectionName: null,
            subSectionName: null,
            subSectionIndex: null
        },
        recommendations: []
    },
    reducers: {
        setCollateral: (state, action) => {
            state.formInstance = action.payload;
            state.sectionToEdit = {
                id: null,
                subSectionIndex: null
            };
        },
        resetCollateral: (state, action) => {
            state.formInstance = null;
            state.sectionToEdit = {
                id: null,
                subSectionIndex: null
            };
        },
        setSectionToEdit: (state, action) => {
            state.sectionToEdit = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchRecommendationsByClauseId.fulfilled, (state, action) => {
            // Add recommendations to the state array
            if (!state.recommendations.some(r => r.id === action.payload.id)) {
                state.recommendations = [...state.recommendations, action.payload];
            }
        })
    },
});

export const { setCollateral, resetCollateral, setSectionToEdit } = CollateralSlice.actions;

export const selectCollateralFormInstance = state => state.collateral.formInstance;
export const selectSectionToEdit = state => state.collateral.sectionToEdit;
export const selectSectionById = (state, id) => state.collateral.formInstance.Sections.filter(s => s.ID === id)[0];

export const selectRecommendationsByClauseId = (state, id) => {
    const section = state.collateral.sectionToEdit;
    const clauseKey = `${section.formName}:${section.sectionName}:${section.subSectionName}:${id}`;

    const clause = state.collateral.recommendations.find(r => r.id === clauseKey);

    if (clause) {
        return clause.recommendations;
    } else {
        return [];
    }
};

export default CollateralSlice.reducer;