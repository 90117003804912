import { AuthenticatedTemplate } from "@azure/msal-react";
import { Box } from "@mui/material";
import React from 'react';
import { CollateralView } from "../../features/collateral/CollateralView";

export const Collateral = (props) => {

    return (
        <AuthenticatedTemplate>
            <Box>
                <CollateralView />
            </Box>
        </AuthenticatedTemplate>
    );
};