import { useState, useEffect } from "react";
import {   useNavigate } from "react-router-dom";
// Material-UI imports
import { Container } from "@mui/material";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

import PageRouting from "./routes/routes"
/*import PageRouting from "./routes/routes"*/
/*import PageRouting from "./routes/routes"*/
/*import PageRouting from "./routes/routes"*/
/*import PageRouting from "./routes/routes"*/
import { PageLayout }  from "./components/layout/PageLayout";


//css file
import "./assets/styles/style.css"
import './assets/fonts/montserrat/font-montserrat.css';

// Class-based equivalents of "Profile" component


// SignalR imports
// import { HubConnectionBuilder } from "@microsoft/signalr";
// import { createConnectionContext } from './context/createConnectionContext';   

// // Create Hub Connection
// const createHubConnection = () => {
//   /* build a connection instance here, for example */
//   return new HubConnectionBuilder()
//   .withUrl("https://localhost:44330/hub/notificationshub", { accessTokenFactory: () => "accessToken" })
//   .withAutomaticReconnect()
//   .build();
// };

// const HubConnectionContext = createConnectionContext(createHubConnection);
import { NotificationHub } from "./components/notification-hub/NotificationHub";

import { SnackbarProvider } from "notistack";

function App({ pca }) {

  
  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            {/* <NotificationHub /> */}
            <PageLayout>
                <Container maxWidth="xl" disableGutters>
                    <PageRouting />
                </Container>
            </PageLayout>
        </SnackbarProvider>
      </MsalProvider>
    </ClientSideNavigation>
  );
}

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */ 
function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

export default App;

