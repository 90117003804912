import { AuthenticatedTemplate } from "@azure/msal-react";
import { Box } from "@mui/material";
import React from 'react';
import { PrepareDocument } from "../../features/eSign/PrepareDocument/PrepareDocument";
import { SignDocument } from "../../features/eSign/SignDocument/SignDocument";

export const Sign = (props) => {

    return (
        <AuthenticatedTemplate>
            <Box>
                <PrepareDocument />
            </Box>
        </AuthenticatedTemplate>
    );
};